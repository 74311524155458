import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">

            <div className="content">
              <div className="grid-containerKontaktowy">
                <div className="formularzkontaktowy">
                  <h1>Formularz kontaktowy</h1><form
                  name="contact"
                  action="https://formspree.io/mbjgkoeo"
                  method="POST"
                >
                  {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                  <input type="hidden" name="form-name" value="contact" />
                  <div hidden>
                    <label>
                      Don’t fill this out:{' '}
                      <input name="bot-field" onChange={this.handleChange} />
                    </label>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor={'name'}>
                     Twoje imię
                  </label>
                    <div className="control">
                      <input
                        className="input"
                        type={'text'}
                        name={'name'}
                        onChange={this.handleChange}
                        id={'name'}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor={'email'}>
                      Email
                  </label>
                    <div className="control">
                      <input
                        className="input"
                        onChange={this.handleChange}
                        id={'email'}
                        required={true}
                        type="text" name="_replyto"
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor={'message'}>
                      Wiadomość
                  </label>
                    <div className="control">
                      <textarea
                        className="textarea"

                        onChange={this.handleChange}
                        id={'message'}
                        required={true}
                        name="message"
                      />
                    </div>
                  </div>
                  <div className="field">
                    <button className="button is-link" type="submit">
                      Wyślij
                  </button>
                  </div>
                </form></div>
                <div className="kontakt"><h1>KONTAKT</h1>
                  <p>
                    Ekochron<br></br>
                    43-426 Ogrodzona, Przemysłowa 15<br></br>
                    woj. śląskie, Polska<br></br>
                    GPS: N 49,768314 E 18,723047   <br></br><br></br>
                    tel. 33 857 90 10, 33 853 32 91
                    </p>

                  <p className="kontaktH5"> Dział obsługi Klientów:</p>
                  <p> tel. 33 857 90 10 wew. 21, 26<br></br>
                    <a href="mailto:sprzedaz@ekochron.pl">e-mail: sprzedaz@ekochron.pl</a></p>
                  <p className="kontaktH5"> Doradztwo techniczne:</p>
                  <p> tel. 33 857 90 10 wew. 17<br></br>
                    <a href="mailto:doradca@ekochron.pl">e-mail: doradca@ekochron.pl</a></p>
                  <p className="kontaktH5"> Doradztwo cynkownia: 503 776 691</p>
                  <br></br>
                </div>
                <div className="jakzamawiac">
                  <h1>Jak zamawiać?</h1>
                  <p>Produkty marki Ekochron zamawiać można na kilka sposobów:<br></br>
                    📞🠞telefonicznie pod numerem <b>33 857 90 10</b>, <b>33 853 32 91</b> wew. 21, 26;<br></br>
                    📧🠞mailowo, pisząc na: sprzedaz@ekochron.pl;<br></br>
                    📝🠞za pomocą formularza kontaktowego dostępnego poniżej lub osobiście w siedzibie firmy,<br></br>
                    💻🠞poprzez skype <a href="skype:ekochron?call">Zadzwoń do nas</a></p><br></br>
                  <ul>
                    <li>Dział handlowy pracuje od poniedziałku do piątku w godzinach od 7.00 do 15.00.</li>
                    <li>Zamówienie towaru jest równoznaczne z jego rezerwacją.</li>
                    <li>W przypadku braku wyrobu na magazynie wyznaczamy konkretną datę gotowości zamówienia, o której telefonicznie lub mailowo informujemy klienta.</li>
                    <li>Czas realizacji wynosi do 5 dni roboczych, a w przypadku wyrobów niestandardowych - maksymalnie do 3 tygodni.</li>
                  </ul>


                </div>
              </div>


            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
